import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/vercel/path0/node_modules/gatsby-theme-carbon/src/templates/Default.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><strong parentName="p">{`Pre Open WOD 6`}</strong></p>
    <p>{`12:00 AMRAP of:`}</p>
    <p>{`50-Thrusters (RX & Scaled=75/55, Masters=65/45)`}</p>
    <p>{`40-KBS (RX=70/45, Scaled=53/35, Masters=45/30)`}</p>
    <p>{`30-OH Walking Lunges (15/leg, Rx & Scaled=45/25`}{`#`}{`, Masters 25/15`}{`#`}{`)`}</p>
    <p>{`20-Calorie Row`}</p>
    <p>{`10-”Stone” clean over shoulder (RX=150/80, Scaled=80/40, Masters=60/30)`}</p>
    <p>{`5-Rope Climbs (RX & Scaled to 15′, Masters to 12′)`}</p>
    <p>{`50ft Handstand Walk (Scaled & Masters=100ft Bear Crawl) (Each 5ft
increment=1 rep)`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      